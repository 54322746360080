// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  qrServiceBaseUrl: 'https://dev-fsiles-qr-myvivienda-service.dev-aks.grupoarpada.com/',
  msalClientId: 'c65c750b-8b10-4792-9704-38edee633a28',
  msalAuthorityUrl: 'https://login.microsoftonline.com/grupoarpada.onmicrosoft.com',
  scopes: ['https://graph.microsoft.com/.default'],
  milisecondsBeforeExpireToRenewAccess: 600000,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
