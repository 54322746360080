<mat-toolbar color="primary">
    <span>Publicador del portal de Myvivenda QR</span>
    <span class="toolbar-spacer"></span>
    <span *ngIf="isLogged">{{username}}</span>
    <span *ngIf="!isLogged">Inicie sesión</span>
</mat-toolbar>
<div class="main-content" layout="row" layout-align="start center" flex>
    <form matForm *ngIf="isLogged" class="contentCenter">
        <p>
            <mat-form-field appearance="standard">
                <mat-label>Código de obra a generar</mat-label>
                <input matInput type="text" placeholder="Código de obra" [(ngModel)]="constructionSiteCode" name="constructionSiteCode">
            </mat-form-field>
        </p>
        <p>
            <button mat-raised-button (click)="callGenerateQRPortal()" color="primary" *ngIf="!loading" type="submit">Procesar ficheros</button>
            <button mat-raised-button color="primary" *ngIf="loading" disabled="true">
                Procesando ficheros <mat-icon><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
            </button>
        </p>
        <p *ngIf="loading">Generando ficheros por favor espere y sea paciente</p>
    </form>
    <div class="contentCenter">
        <p *ngIf="!isLogged">Inicie sesión antes de continuar</p>
        <p *ngIf="hasError" class="warnColor">{{errorMessage}}</p>
    </div>
    <div *ngIf='validGeneratePortalQrResponse'>
        <mat-divider></mat-divider>
        <h2>Subir a la web el código de obra {{constructionSiteCodeForUpload}}:</h2>
        <div>
            <mat-checkbox color="primary" [(ngModel)]="acceptedVerifiedFiles" class="fullLowMargin">
                He verificado las viviendas y los archivos generados y quiero subir los ficheros
            </mat-checkbox>
            <br/>
            <button mat-raised-button color="primary" *ngIf="!loadingUpload" [disabled]="!acceptedVerifiedFiles" class="fullLowMargin"
                (click)="callUploadQRPortal()">
                Subir ficheros a la web
            </button>
            <button mat-raised-button color="primary" *ngIf="loadingUpload" disabled="true"  class="fullLowMargin">
                Subir ficheros a la web <mat-icon><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
            </button>
            <p *ngIf="hasErrorUpload" class="warnColor">{{errorMessageUpload}}</p>
            <p *ngIf="isGoodUploaded" class="primaryColor fullLowMargin">Se ha subido correctamente los ficheros a la web</p>
        </div>
        <mat-divider></mat-divider>
        <h2>Ficheros generados:</h2>
        <div class="contentCenter flexGridLayout">
            <app-sharepoint-file-url 
                *ngFor="let sharepointFile of resultGeneratePortalQrService.sharepoint_files_urls"
                [sharepointFile]="sharepointFile"
                class="fullLowMargin"
            ></app-sharepoint-file-url>
        </div>
        <mat-divider></mat-divider>
        <h2>Previsualizacion de viviendas: </h2>
        <div class="contentCenter flexGridLayout">
            <app-house-card
                *ngFor="let house of resultGeneratePortalQrService.houses_info"
                [house]="house"
                class="fullLowMargin"
            ></app-house-card>
        </div>
    </div>
</div>