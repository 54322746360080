import { Component, Input, OnInit } from '@angular/core';
import { SharepointFile } from '../models/generatePortal.qrServiceResponse';

@Component({
  selector: 'app-sharepoint-file-url',
  templateUrl: './sharepoint-file-url.component.html',
  styleUrls: ['./sharepoint-file-url.component.scss']
})
export class SharepointFileUrlComponent implements OnInit {

  @Input()
  sharepointFile: SharepointFile = {url: '', filename: ''};

  constructor() { }

  ngOnInit(): void {
  }

}
