import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, BrowserAuthError} from '@azure/msal-browser';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { GeneratePortalQrServiceResponse } from '../models/generatePortal.qrServiceResponse';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  public loading: boolean = false;
  public isLogged: boolean = false;
  public hasError: boolean = false;
  public username: string = '';
  public constructionSiteCode: string = '';
  public errorMessage: string = '';
  /*public constructionSiteCodeForUpload: string = 'aedas-dev-fsiles';
  public validGeneratePortalQrResponse: boolean = true;
  public resultGeneratePortalQrService: GeneratePortalQrServiceResponse = {
    "sharepoint_files_urls": [
      {
        "url": "https://grupoarpada.sharepoint.com/sites/data-QR-Myvivienda/Documentos%20compartidos/desarrollo/dev-fsiles/resultados-workspace-promociones/aedas-dev-fsiles/web_aedas-dev-fsiles.zip",
        "filename": "web_aedas-dev-fsiles.zip"
      },
      {
        "url": "https://grupoarpada.sharepoint.com/sites/data-QR-Myvivienda/Documentos%20compartidos/desarrollo/dev-fsiles/resultados-workspace-promociones/aedas-dev-fsiles/data_aedas-dev-fsiles.zip",
        "filename": "data_aedas-dev-fsiles.zip"
      }
    ],
    "houses_info": [
      {
        "house_uuid": "767b4764-b302-5626-89b9-93177a355f86",
        "description_line1": "Portal 1",
        "description_line2": "Bajo A"
      },
      {
        "house_uuid": "4a066677-db76-5cb7-87cf-7b6ad7db512d",
        "description_line1": "Portal 1",
        "description_line2": "Bajo B"
      },
      {
        "house_uuid": "e0615f48-fe5d-5d3d-a43f-05082787f35d",
        "description_line1": "Portal 1",
        "description_line2": "Bajo C"
      },
      {
        "house_uuid": "afbcb97a-7485-593c-ae4c-fa1f979b864b",
        "description_line1": "Portal 1",
        "description_line2": "Bajo D"
      }
    ],
    "status": "ok"
  };*/
  public constructionSiteCodeForUpload: string = '';
  public validGeneratePortalQrResponse: boolean = false;
  public resultGeneratePortalQrService: GeneratePortalQrServiceResponse = null;
  public acceptedVerifiedFiles: boolean = false;
  public loadingUpload: boolean = false;
  public hasErrorUpload: boolean = false;
  public errorMessageUpload: string = '';
  public isGoodUploaded: boolean = false;
  private accessToken: string = '';
  private readonly LOCAL_STORAGE_AUTH_RESULT_KEY: string = 'authResult';
  private readonly LOCAL_STORAGE_WAITING_REDIRECT_KEY: string = 'waitingRedirect';
  private readonly INVALID_MICROSOFT_REDIRECT_CODE = 'Se ha recibido un código inválido en la redirección de microsoft';
  private readonly INVALID_MICROSOFT_REDIRECT_UNKNOWN = 'Se ha producido un error inexperado en la redirección de microsoft';
  private readonly UNDEFINED_ACCESS_TOKEN_ON_AUTH_RESPONSE = 'El login pareció correcto pero no se puede obtener un token de acceso desde microsoft';

  constructor(
    private msalService: MsalService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if(!window['developerArea']){
      window['developerArea'] = {};
    }
    window['developerArea'].homeComponent = this;
    this.route.fragment.subscribe((fragment: string) => {
      if(fragment && fragment.length > 0 && fragment.indexOf('code=') >= 0){
        // Es redirect de microsoft
        localStorage.setItem(this.LOCAL_STORAGE_WAITING_REDIRECT_KEY, 'true');
        this.msalService.handleRedirectObservable('#'+fragment).subscribe(
          (result: AuthenticationResult)=>{
            if (result){
              localStorage.removeItem(this.LOCAL_STORAGE_WAITING_REDIRECT_KEY);
              localStorage.setItem(this.LOCAL_STORAGE_AUTH_RESULT_KEY, JSON.stringify(result));
              this.initVariablesFromLoggedInfo(result);
            } else {
              this.hasError = true;
              this.errorMessage = this.INVALID_MICROSOFT_REDIRECT_CODE;
            }
          },
          (error)=>{
            localStorage.removeItem(this.LOCAL_STORAGE_WAITING_REDIRECT_KEY);
            this.hasError = true;
            if (error && error.message){
              this.errorMessage = '' + error.message;
            } else if(error) {
              this.errorMessage = '' + error;
            } else {
              this.errorMessage = this.INVALID_MICROSOFT_REDIRECT_UNKNOWN;
            }
            console.error('Error cuando se llama al handler del login: ', error);
          }
        )
      }
    });
    if(!localStorage.getItem(this.LOCAL_STORAGE_WAITING_REDIRECT_KEY)){
      let authResultStr: string = localStorage.getItem(this.LOCAL_STORAGE_AUTH_RESULT_KEY);
      if (!authResultStr){
        this.callLogin();
      } else {
        let authResult: AuthenticationResult = JSON.parse(authResultStr);
        if(new Date(authResult.expiresOn).getTime() < new Date().getTime()){
          // Expired token
          this.callLogin();
        } else {
          this.renewAuthResultWhenTokenWillExpire(authResult);
          this.initVariablesFromLoggedInfo(authResult);
        }
      }
    }
  }

  public developerRenewAuthResultWhenTokenWillExpire(){
    const authResultStr: string = localStorage.getItem(this.LOCAL_STORAGE_AUTH_RESULT_KEY);
    const authResult: AuthenticationResult = JSON.parse(authResultStr);
    this.renewAuthResultWhenTokenWillExpire(authResult);
  }

  public forceRenewAuthToken(
    callbackOnSuccess?:(authResultRefresh: AuthenticationResult)=>void, 
    callbackOnError?:(error: any)=>void,
  ){
    const authResultStr: string = localStorage.getItem(this.LOCAL_STORAGE_AUTH_RESULT_KEY);
    const authResult: AuthenticationResult = JSON.parse(authResultStr);
    this.callMsalRenewToken(authResult, callbackOnSuccess, callbackOnError);
  }

  private callMsalRenewToken(
    lastAuthResult: AuthenticationResult, 
    callbackOnSuccess?:(authResultRefresh: AuthenticationResult)=>void, 
    callbackOnError?:(error: any)=>void,
  ){
    this.msalService.acquireTokenSilent({
      scopes: environment.scopes,
      authority: lastAuthResult.authority,
      account: lastAuthResult.account,
      correlationId: lastAuthResult.correlationId,
      forceRefresh: true,
    }).subscribe((authResultRefresh: AuthenticationResult)=>{
      this.initVariablesFromLoggedInfo(authResultRefresh);
      this.renewAuthResultWhenTokenWillExpire(authResultRefresh);
      if(callbackOnSuccess){
        callbackOnSuccess(authResultRefresh);
      }
    },(error)=>{
      sessionStorage.clear();
      localStorage.removeItem(this.LOCAL_STORAGE_AUTH_RESULT_KEY);
      console.error('Error al auto-renovar la sesión: ', error)
      if(callbackOnError){
        callbackOnError(error);
      } else {
        window.alert('No se pudo auto-renovar la sesion. Por favor recargue la página si quiere generar mas QR o subirlos a la web. Si no la recarga solo podra seguir viendo las viviendas ya generadas.');
      }
    });
  }
 
  private renewAuthResultWhenTokenWillExpire(lastAuthResult: AuthenticationResult) {
    const lastAuthExpiredTime = new Date(lastAuthResult.expiresOn).getTime();
    const currentTime = new Date().getTime();
    const milisecondsBeforeExpireToRenewAccess = environment.milisecondsBeforeExpireToRenewAccess;
    let timeoutToDoIt = lastAuthExpiredTime - currentTime - milisecondsBeforeExpireToRenewAccess;
    if (timeoutToDoIt < 0){
      timeoutToDoIt = 0;
    }
    window.setTimeout(()=>{
      this.callMsalRenewToken(lastAuthResult);
    }, timeoutToDoIt)
  }

  private initVariablesFromLoggedInfo(authResult: AuthenticationResult){
    if(authResult){
      this.username = authResult.account.username;
      if(authResult.accessToken){
        this.isLogged = true;
        this.accessToken = authResult.accessToken;
      } else {
        this.isLogged = false;
        this.hasError = true;
        this.errorMessage = this.UNDEFINED_ACCESS_TOKEN_ON_AUTH_RESPONSE;
      }
    } else {
      this.username = null;
      this.isLogged = false;
      this.accessToken = null;
    }
  }
  
  callLogin() {
    this.msalService.loginRedirect()
      .subscribe({
        next: () => {
          // No sirve. Como hace redirect esto se pierde
        },
        error: (error) => {
          if (error instanceof BrowserAuthError){
            let authError: BrowserAuthError = error;
            if(authError && authError.errorCode == 'interaction_in_progress'){
              sessionStorage.clear();
              localStorage.removeItem(this.LOCAL_STORAGE_AUTH_RESULT_KEY);
              this.callLogin();
            } else {
              this.hasError = true;
              this.errorMessage = authError.errorMessage;
            }
          } else {
            this.hasError = true;
            this.errorMessage = '' + error;
          }
          console.error('Error llamando al redirect de microsoft: ', error)
        }
      });
  }

  callGenerateQRPortal() {
    this.hasError = false;
    this.errorMessage = '';
    this.hasErrorUpload = false;
    this.errorMessageUpload = '';
    this.acceptedVerifiedFiles = false;
    this.loading = true;
    this.validGeneratePortalQrResponse = false;
    this.constructionSiteCodeForUpload = '';
    this.isGoodUploaded = false;
    let constructionSiteCodeSaved = this.constructionSiteCode;
    this.forceRenewAuthToken(
      (authResultRefresh: AuthenticationResult)=>{
        this.httpClient.get<GeneratePortalQrServiceResponse>(
          environment.qrServiceBaseUrl + 'services/qr-myvivienda/generate-qr-portal',
          {
            headers: {'x-bearer-token': this.accessToken},
            params: {'promotion_code': constructionSiteCodeSaved},
          }
        ).subscribe(
          (result: GeneratePortalQrServiceResponse)=>{
            this.loading = false;
            this.validGeneratePortalQrResponse = true;
            this.resultGeneratePortalQrService = result;
            this.constructionSiteCodeForUpload = constructionSiteCodeSaved;
          },
          (error: HttpErrorResponse)=>{
            this.loading = false;
            this.hasError = true;
            if (error && error.error && error.error.message) {
              this.errorMessage = error.error.message;
            } else if(error.message){
              this.errorMessage = error.message;
            } else {
              this.errorMessage = '' + error;
            }
            console.error('Error llamando al servicio web de generar el portal QR:', error)
          }
        )
      },
      (error: any)=>{
        this.loading = false;
        this.hasError = true;
        if (error && error.error && error.error.message) {
          this.errorMessage = error.error.message;
        } else if(error.message){
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '' + error;
        }
        console.error('Error llamando al servicio web de generar el portal QR:', error)
      },
    ); 
  }

  callUploadQRPortal() {
    this.hasErrorUpload = false;
    this.errorMessageUpload = '';
    this.loadingUpload = true;
    this.isGoodUploaded = false;
    this.forceRenewAuthToken(
      (authResultRefresh: AuthenticationResult)=>{
        this.httpClient.get<GeneratePortalQrServiceResponse>(
          environment.qrServiceBaseUrl + 'services/qr-myvivienda/upload-qr-portal',
          {
            headers: {'x-bearer-token': this.accessToken},
            params: {'promotion_code': this.constructionSiteCodeForUpload},
          }
        ).subscribe(
          (result: any)=>{
            this.loadingUpload = false;
            this.isGoodUploaded = true;
          },
          (error: HttpErrorResponse) => {
            this.loadingUpload = false;
            this.hasErrorUpload = true;
            if (error && error.error && error.error.message) {
              this.errorMessageUpload = error.error.message;
            } else if(error.message){
              this.errorMessageUpload = error.message;
            } else {
              this.errorMessageUpload = '' + error;
            }
            console.error('Error llamando al servicio web de subir el portal QR:', error)
          }
        )
      },
      (error: any)=>{
        this.loadingUpload = false;
        this.hasErrorUpload = true;
        if (error && error.error && error.error.message) {
          this.errorMessageUpload = error.error.message;
        } else if(error.message){
          this.errorMessageUpload = error.message;
        } else {
          this.errorMessageUpload = '' + error;
        }
        console.error('Error llamando al servicio web de subir el portal QR:', error)
      },
    ); 
  }

}
