import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HouseInfo } from '../models/generatePortal.qrServiceResponse';

@Component({
  selector: 'app-house-card',
  templateUrl: './house-card.component.html',
  styleUrls: ['./house-card.component.scss']
})
export class HouseCardComponent implements OnInit {

  @Input()
  house: HouseInfo = {description_line1:'', description_line2: '', house_uuid:''}

  constructor() { }

  ngOnInit(): void {
  }

  getUrlFromHouseUUID(): string {
    let baseUrl = environment.qrServiceBaseUrl;
    if (!baseUrl.endsWith('/')){
      baseUrl = baseUrl + '/';
    }
    return baseUrl + this.house.house_uuid;
  }

}
